import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import InvestigationTable from '../components/InvestigationsTable';
import GenericCard from '../components/Nodes/GenericCard';
import AccountTreeIcon from '@mui/icons-material/AccountTreeOutlined';
import WalletIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PersonIcon from '@mui/icons-material/Person2Outlined';
import Storage from '@mui/icons-material/StorageOutlined';
import InformationCard from '../components/InformationCard';
import DefaultLayout from '../components/DefaultLayout';
import { mainListItems } from '../components/listItems';
import { useNavigate } from 'react-router-dom';

function Copyright(props:any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function DashboardContent() {
  const navigate = useNavigate();

  return (
    <DefaultLayout items ={()=>mainListItems(navigate)}>
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid display={"flex"} flexDirection="row" alignItems={"flex-start"} gap={5}>
              <GenericCard  icon = {<WalletIcon/>} title="Crédito disponível" value={100}/>
              <GenericCard icon = {<AccountTreeIcon/>}title="Investigações salvas" value={150}/>
              <GenericCard icon = {<Storage/>}title="Dados pesquisados" value={300}/>
              <GenericCard icon = {<PersonIcon/>}title="Entidades" value={500}/>
            </Grid>
            <Grid mt={2} display={"flex"} flexDirection="row" alignItems={"flex-start"} gap={5}>
              {/* <Chart/> */}
            <Grid display={"flex"} flexDirection="column" alignItems={"flex-start"} gap={5}>
              <InformationCard/>
              <InformationCard/>
            </Grid>
            </Grid>
              <Grid mt={2} xs={12} md={12} lg={12}>
                  <InvestigationTable data={[]} />
              </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
    </DefaultLayout>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}