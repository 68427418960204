import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import AccountTree from '@mui/icons-material/AccountTreeOutlined';
import AccountBox from '@mui/icons-material/AccountBoxOutlined';
import Shield from '@mui/icons-material/ShieldOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { DomainOutlined, Person2Outlined, BalanceOutlined, GavelOutlined, LocationOnOutlined, CallOutlined, MailOutlined } from '@mui/icons-material';
import { EntityTypes } from "./Nodes/EntityTypes";

export const mainListItems = (navigate: any) => (
  <React.Fragment >
    <ListItemButton>
      <ListItemIcon>
        <DashboardIcon sx={{ color: "primary.contrastText" }} />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AccountTree sx={{ color: "primary.contrastText" }} />
      </ListItemIcon>
      <ListItemText onClick={() => navigate('/board')} primary="Investigações" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AccountBox sx={{ color: "primary.contrastText" }} />
      </ListItemIcon>
      <ListItemText primary="Usuários" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <Shield sx={{ color: "primary.contrastText" }} />
      </ListItemIcon>
      <ListItemText primary="Grupo de Segurança" />
    </ListItemButton>
  </React.Fragment>
);

export const investigationBoardList = () => {
  const onDragStart = (event: any, data: any) => {
    event.dataTransfer.setData('text/plain', JSON.stringify(data));
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <React.Fragment >
      <ListItemButton onDragStart={(event) => onDragStart(event, { nodeType: 'genericNode', entityType: EntityTypes.Company })} draggable>
        <ListItemIcon>
          <DomainOutlined sx={{ color: "primary.contrastText" }} />
        </ListItemIcon>
        <ListItemText primary="Empresa" />
      </ListItemButton>
      <ListItemButton onDragStart={(event) => onDragStart(event, { nodeType: 'genericNode', entityType: EntityTypes.Person })} draggable>
        <ListItemIcon>
          <Person2Outlined sx={{ color: "primary.contrastText" }} />
        </ListItemIcon>
        <ListItemText primary="Pessoa" />
      </ListItemButton>
      <ListItemButton onDragStart={(event) => onDragStart(event, { nodeType: 'genericNode', entityType: EntityTypes.Bank })} draggable>
        <ListItemIcon>
          <BalanceOutlined sx={{ color: "primary.contrastText" }} />
        </ListItemIcon>
        <ListItemText primary="Banco" />
      </ListItemButton>
      <ListItemButton onDragStart={(event) => onDragStart(event, { nodeType: 'genericNode', entityType: EntityTypes.Process })} draggable>
        <ListItemIcon>
          <GavelOutlined sx={{ color: "primary.contrastText" }} />
        </ListItemIcon>
        <ListItemText primary="Processo" />
      </ListItemButton>
      <ListItemButton onDragStart={(event) => onDragStart(event, { nodeType: 'genericNode', entityType: EntityTypes.Address })} draggable>
        <ListItemIcon>
          <LocationOnOutlined sx={{ color: "primary.contrastText" }} />
        </ListItemIcon>
        <ListItemText primary="Endereço" />
      </ListItemButton>
      <ListItemButton onDragStart={(event) => onDragStart(event, { nodeType: 'genericNode', entityType: EntityTypes.Phone })} draggable>
        <ListItemIcon>
          <CallOutlined sx={{ color: "primary.contrastText" }} />
        </ListItemIcon>
        <ListItemText primary="Telefone" />
      </ListItemButton>
      <ListItemButton onDragStart={(event) => onDragStart(event, { nodeType: 'genericNode', entityType: EntityTypes.Email })} draggable>
        <ListItemIcon>
          <MailOutlined sx={{ color: "primary.contrastText" }} />
        </ListItemIcon>
        <ListItemText primary="Email" />
      </ListItemButton>
    </React.Fragment>
  )
};

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div">
      Últimas Investigações salvas
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Juliano da Silva" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Adriano da Silva" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="João Silva" />
    </ListItemButton>
  </React.Fragment>
);