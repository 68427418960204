import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as Logo } from '../images/investigacoes_logo.svg';

const InvestigacoesLogo:React.FC<{displayText?:boolean}> = ({displayText=true}) => {
    return(
        <Grid display={"flex"}>
        <Logo />
        <Typography ml={2} variant="h5" color={"white"} display="flex" alignItems={"center"} justifyContent="space-around">
           {displayText?"Investigações":null}
        </Typography>
        </Grid>
      )
  }
  export default InvestigacoesLogo