
export enum EntityTypes {
    Company = "Company",
    Person = "Person",
    Bank = "Bank",
    Process = "Process",
    Address = "Address",
    Phone = "Phone",
    Email = "Email"
}
