import * as React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {red} from '@mui/material/colors';

ReactDOM.render(<React.StrictMode>
  <React.Fragment>
    <CssBaseline />
    <AuthProvider >
    <LocalizationProvider dateAdapter={AdapterDayjs}> 
    <App />
    </LocalizationProvider>
    </AuthProvider>
  </React.Fragment>
  </React.StrictMode>, document.getElementById('root') as HTMLElement);

