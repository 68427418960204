// src/components/InvestigationTable.tsx

import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    IconButton,
    TablePagination,
    Grid,
    Typography,
    Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from "@mui/icons-material/Add";
import { SecondaryButton,PrimaryButton } from './StyledButton';

interface Investigation {
    id: number;
    name: string;
    entityCount: number;
    creationDate: string;
    updateDate: string;
}

interface InvestigationTableProps {
    data: Investigation[];
}

const InvestigationTable: React.FC<InvestigationTableProps> = ({ data }) => {
    const sampleData: Investigation[] = [
        {
          id: 1,
          name: 'Investigation 1',
          entityCount: 10,
          creationDate: '2022-01-01',
          updateDate: '2022-01-02',
        },
        {
          id: 2,
          name: 'Investigation 2',
          entityCount: 20,
          creationDate: '2022-01-10',
          updateDate: '2022-01-15',
        },
        // ... add more investigations
      ];
    const [selected, setSelected] = useState<number[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const handleClick = (id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Paper
        sx={{
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "24px",
            gap: "24px",
            background: "#FFFFFF",
            border: "2px solid #F2F2F2",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
            borderRadius: "8px",
        }}
         >
            <Grid
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            padding={0}
            gap={5}
            >
            <Typography variant='h5' fontWeight={"bold"}> 
            Últimas Investigações
            </Typography>

            <Grid
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            padding={0}
            gap={5}
            >
                <SecondaryButton variant="contained">Ver Todas </SecondaryButton>
                <PrimaryButton variant="contained" startIcon={<AddIcon />}>Nova investigação</PrimaryButton>
            </Grid>

            </Grid>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell>Nome da Investigação</TableCell>
                            <TableCell>Numero de Entidades</TableCell>
                            <TableCell>Data da Criação</TableCell>
                            <TableCell>Data de atualização</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sampleData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                const isRowSelected = isSelected(row.id);
                                return (
                                    <TableRow key={row.id} selected={isRowSelected}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isRowSelected}
                                                onClick={() => handleClick(row.id)}
                                            />
                                        </TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.entityCount}</TableCell>
                                        <TableCell>{row.creationDate}</TableCell>
                                        <TableCell>{row.updateDate}</TableCell>
                                        <TableCell>
                                            <IconButton>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"

                count={sampleData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default InvestigationTable;