import * as React from 'react';
import { Paper } from '@mui/material';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

const InformationCard:React.FC<{}> = () => {
  return (
      <Paper sx={{
        boxSizing: "border-box",
        width:"538px",
        height:"167.5px",
        background:"#FFFFFF",
        border:"2px solid #F2F2F2",
        boxShadow:"0px 4px 16px rgba(0, 0, 0, 0.08)",
        borderRadius:"8px",
        }}>
        
      </Paper>
  );
}

export default InformationCard;