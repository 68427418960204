import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ReactComponent as Computador } from '../images/computador.svg';
import { ReactComponent as Accounts } from '../images/accounts.svg';
import InvestigacoesLogo from '../components/InvestigacoesLogo';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        CheckMatch
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignInSide() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const data = new FormData(event.currentTarget);
      login({username:data.get('email') as string});
      console.log({
        email: data.get('email'),
        password: data.get('password'),
      });
      navigate('/protected');
    }catch(error){
      navigate('/login');
    }
  };


  return (
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={5}
          lg={5}
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.primary.dark : t.palette.primary.light,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          justifyContent="center"
          direction={"column"}
          display="flex"
          container
        >
            <Grid item 
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              justifyItems={"center"}
              xs={2}
               >
                <Box mt={5} ml={5}>
                  <InvestigacoesLogo/>
                </Box>
            </Grid>

            <Grid item
              justifyContent="center"
              alignItems="flex-end"
              justifyItems={"center"}
              display="flex"
             xs={5} >
                <Accounts/>
                <Computador/>
            </Grid>
            <Grid 
            item
            alignItems="center"
            direction="column"
            display="flex"
            marginX={5}
            xs={5} >
              <Grid
                alignItems="flex-end">
                <Typography mt={5} fontWeight="bold" variant="h5" sx={{color:"primary.main"}}>
                  Dados distribuídos em um só lugar.
                </Typography>
                <Typography mt={2} variant="body1" color={"white"}>
                  Centralizando informações dispersas, os dados distribuídos em um só lugar facilitam a análise e tomada de decisões. Essa unificação otimiza processos e promove a integração entre setores. Tal centralização garante maior segurança e eficiência no gerenciamento das informações.
                </Typography>
              </Grid>
            </Grid>
        </Grid>
        <Grid item 
        display={"flex"}
        justifyItems={"center"}
        alignItems="center"
        justifyContent="center"
          xs={12} sm={8} md={7} lg={7} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
            Faça seu login
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Entrar
              </Button>
              <Grid container>
                <Grid item xs>
                  Esqueceu sua senha?<Link href="#" variant="body2"> Redefinir senha
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
  );
}