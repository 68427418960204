// src/theme.ts
import { createTheme } from '@mui/material/styles';

const customPalette = {
  primary: {
    main: '#398EFE',
    light: '#398EFE',
    dark: '#122238',
    hover: '#2A6EC7',
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: '#398EFE',
    light: '#FFFFFF',
    dark: '#398EFE',
    hover: '#398EFE',
    contrastText: "#FFFFFF",
  },
  error: {
    main: '#FFFFFF',
    light: '#FFFFFF',
    dark: '#ffffff',
    hover: '#DA203D',
    contrastText: "#DA203D",
  },
  neutral:{
    main: '#F2F2F2',
    light: '#F2F2F2',
    dark: '#F2F2F2',
    hover: '#F2F2F2',
    contrastText: "#DA203D",
  }
};

const theme = createTheme({
  palette: customPalette,
});

export default theme;
