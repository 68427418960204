const companyNames = [
    "TechSphere",
    "Innovatia",
    "DataVoyage",
    "SynergyWorks",
    "NetLocus",
    "CloudHorizon",
    "GlobalDynamics",
    "NexaSolutions",
    "IntelliStream",
    "BrightMinds",
    "AlphaNimbus",
    "QuantumLeap",
    "OmniVision",
    "FusionCore",
    "SmartFlow",
    "GigaSystems",
    "EcoSustain",
    "SpectraLink",
    "CyberNaut",
    "SolarFlare",
    "LuminaNetworks",
    "CogniSense",
    "EmpowerSoft",
    "AeroWave",
    "UltraLogic",
    "InfiniteReach",
    "PrimeTech",
    "ProtonWise",
    "ChronoLabs",
    "NeuroPulse",
    "FlexiGrid",
    "AstralAxis",
    "QuantumMetrics",
    "DigitalEssence",
    "BlueOcean",
    "SolarisInnovations",
    "DeepThink",
    "NimbusSystems",
    "RadiantFusion",
    "ZenithSolutions",
    "GeoFrontiers",
    "DynaFlux",
    "HyperCircuit",
    "NovaPlex",
    "EagleEye",
    "NeuralNet",
    "OptimaTech",
    "QuantumDynamics",
    "DeltaCore",
    "SiliconValleyTech",
  ];

export function getRandomInt(min:number, max:number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  
  function getRandomLabel() {
    const labels = companyNames;
    return labels[getRandomInt(0, labels.length - 1)];
  }
  
  function getRandomPosition() {
    return {
      x: getRandomInt(0, 500),
      y: getRandomInt(0, 500),
    };
  }
  
  export function createRandomNodes(parentId: string) {
    const nodes = [];
  
    for (let i = 1; i <= 10; i++) {
      const node = {
        id: `${parentId}.${i.toString()}`,
        type: "genericNode",
        data: {label: getRandomLabel()},
        position: getRandomPosition(),
      };
  
      nodes.push(node);
    }
  
    return nodes;
  }

  export function createRandomNode(nodeId: string,position:{x:number,y:number},entityType:string,nodeType:string) {
      return {
        id: `${nodeId}`,
        type: nodeType,
        data: { entityType, label: getRandomLabel() },
        position: position
      };
  }
