import { Grid } from '@mui/material';
import ArrowRight from '@mui/icons-material/ArrowRight';
import React, { useCallback } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import { getIcon } from './getIcon';
import { createRandomNodes } from '../../utils/randomNodes';
import { NodeInterface } from '../../pages/data';

const GenericNode: React.FC<NodeInterface & {displayIcon:boolean}> = (props) => {
  const reactFlowInstance = useReactFlow();
  const node = reactFlowInstance.getNode(props.id)
  
  const onClick = useCallback(
    (event: any, node: NodeInterface) => {
      event.preventDefault();

      // check if the dropped element is valid
      if (typeof node.data === 'undefined' || !node.data) {
        return;
      }

      const parentNodeId = node.id.split(".")[0];
      if(parentNodeId === node.id) return;

      //Fazer a pesquisa no back e trazer os novos nodes
      const newNodes = createRandomNodes(node.id);
      
      //Não vai precisar disso quando implementar a parte de cima.
      reactFlowInstance.addEdges(newNodes.map((n: any) => ({ id:`e${node.id}-${n.id}`, source: node.id, target: n.id })));
      reactFlowInstance.addEdges({ id:`e1-${node.id}`, source: "1", target: node.id });
      
      reactFlowInstance.setNodes((nds) => {
        const copiedNodes = nds.slice();
        const parentNode = copiedNodes.find((n: any) => n.id === parentNodeId);
        if (parentNode === undefined) return copiedNodes;
        const nodeIndex = parentNode?.data.findIndex((n: any) => n.id === node.id);
        parentNode?.data.splice(nodeIndex, 1);

        if (parentNode?.data.length === 0) {
          const parentIndex = copiedNodes.findIndex((n: any) => n.id === parentNodeId);
          copiedNodes.splice(parentIndex, 1);
        }
        parentNode.data = parentNode.data.slice();
        return copiedNodes.concat(node, newNodes);
      })

    },
    [reactFlowInstance]
  );

  return (
    <>
      <Handle type="target" position={Position.Top} id={props.id}/>
      <Grid
        container
        direction="row"
        display={"flex"}
        alignItems="center"
        spacing={1}
        justifyContent="space-between"
        flexGrow={1}
        sx={{
          boxSizing: 'border-box',
          padding: '2px 4px',
          background: props.displayIcon ===false?'#FFFFFF':null,
          border: props.displayIcon ===false? '1px solid #DDDDDD':null,
          borderRadius: props.displayIcon ===false? '8px':null,
          height: "100%"
        }}
      >
          {props.displayIcon ===false? null:
          <Grid item>
            {getIcon(props.data.entityType)}
          </Grid>
          }
          <Grid item
              direction="column"
              alignItems="center">
              <Grid item>
              <span style={{
                  display: 'inline-block',
                  maxWidth: "102px",
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>
                  {props.data.label}
                </span>
              </Grid>
              <Grid item>
              <span style={{
                  display: 'inline-block',
                  maxWidth: "102px",
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>
                  {props.data.label}
                </span>
              </Grid>
          </Grid>
          <Grid item>
            <ArrowRight onClick={(event) => onClick(event, props)} />
          </Grid>
      </Grid>
      <Handle type="source" position={Position.Bottom} id={props.id} />
    </>
  )
}

export default GenericNode;