import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

  const PrimaryButton = styled(Button)(({ theme }) => ({
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      boxShadow:"0px 4px 16px rgba(0, 0, 0, 0.08)",
      borderRadius: "10px",
      '&:hover': {
        backgroundColor: theme.palette.primary.hover,
        opacity: 0.7,
      },
    }));

  const SecondaryButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    border: "1.5px solid #398EFE",
    boxShadow:"0px 4px 16px rgba(0, 0, 0, 0.08)",
    borderRadius: "10px",
    '&:hover': {
      backgroundColor: theme.palette.secondary.hover,
      color: theme.palette.secondary.contrastText,
      opacity: 0.7,
    },
  }));

  const SecondaryDeleteButton = styled(Button)(({ theme }) => 
  {
    console.log(theme)
    return ({
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.hover,
      opacity: 0.7,
    },
  })
  }
  );

  export { SecondaryButton,PrimaryButton}