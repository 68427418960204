import { Edge, Position } from "reactflow";

export function parseInvestigationResponseDataEntitiesToNodeInterface(): NodeInterface[] {
  return data.data.entities.map((entity: any) => {
    if(entity.entityIdentification === EntityIdentification.LitLawsuit){
      return{
        id: entity.id,
      type: "genericNode",
      data: {
        entityType: entity.entityIdentification,
        label: entity.displayValue,
      },
      position: { x: entity.positionX, y: entity.positionY },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      parent: "0b7946e9-283e-44db-86cc-5df8c00bce1e",
      extent: "parent"
      }
    }
    return {
      id: entity.id,
      type: "genericNode",
      data: {
        entityType: entity.entityIdentification,
        label: entity.displayValue,
      },
      position: { x: entity.positionX, y: entity.positionY },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    };
  });
}

export function parseInvestigationResponseDataLinksToEdge(): Edge[] {
  return data.data.links.map((link: any) => {
    return {
      id: link.id,
      source: link.source,
      target: link.target,
      label: link.label,
      // type: link.style,
      arrowHeadType: link.router,
      style: { stroke: link.color },
    };
  });
}

export interface NodeInterface {
  id: string;
  type: string;
  data: { entityType: string; label: string };
  position: { x: number; y: number };
  sourcePosition: Position;
  targetPosition: Position;
}

export interface GroupNodeInterface {
  id: string;
  type: string;
  data: NodeInterface[];
  position?: { x: number; y: number };
  sourcePosition?: Position;
  targetPosition?: Position;
}

export interface InvestigationResponse {
  status: boolean;
  data: Data;
}

export interface Data {
  id: string;
  name: string;
  userId: string;
  positionX: number;
  positionY: number;
  scale: number;
  entities: Entity[];
  links: Link[];
}

export interface Entity {
  displayValue: string;
  entityIdentification: EntityIdentification;
  extraParams: ExtraParam[];
  id: string;
  matchValue: string;
  positionX: number;
  positionY: number;
}

export enum EntityIdentification {
  LitCompany = "lit.company",
  LitLawsuit = "lit.lawsuit",
  LitLawyer = "lit.lawyer",
  LitPerson = "lit.person",
  LitPhone = "lit.phone",
  LitUnknown = "lit.unknown",
}

export interface ExtraParam {
  id: string;
  entityId: string;
  paramId: string;
  displayValue: string;
  value: string;
  type: Type;
  deletedAt: null;
  deletedBy: null;
  createdAt: Date;
  updatedAt: Date;
}

export enum Type {
  String = "string",
}

export interface Link {
  id: string;
  investigationId: string;
  source: string;
  target: string;
  label: string;
  color: Color;
  style: Style;
  router: Router;
  deletedAt: null;
  deletedBy: null;
  createdAt: Date;
  updatedAt: Date;
}

export enum Color {
  Ccc = "#ccc",
  F00 = "#f00",
  The4D86B6 = "#4d86b6",
}

export enum Router {
  Metro = "metro",
  Orthogonal = "orthogonal",
}

export enum Style {
  Dashed = "dashed",
}

export const data = {
  status: true,
  data: {
    id: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
    name: "TESTE_DENIS",
    userId: "a4d437ed-b2ef-4c5a-abc9-2ab5c95b294c",
    positionX: 782.14,
    positionY: -637,
    scale: 0.9239110918663,
    entities: [
      {
        displayValue: "DCL",
        entityIdentification: "lit.person",
        extraParams: [
          {
            id: "2109cb07-cd1f-4256-b375-c867c0aab406",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "341f4699-0a6c-4c34-99fe-454877d2a2f9",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "38712d06-48b4-4a2c-ad30-e566b050025f",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "3892769f-19d4-4d1f-b6bf-699dc1c9b20c",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "4071c984-e699-4bea-bada-3e2b9e5d778b",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "41daf947-e3f6-4caa-b3c2-1326c9a41533",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "461b97a4-ecd0-498c-a6e8-95264b742045",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "50cd4c9a-9250-444f-a339-0a69329f39e8",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "51bac2f3-4306-44bb-9dbf-73220228a4bf",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "638eec65-d550-4827-a41b-553e2d9a95fd",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "7687ea68-bd98-4952-860b-35c9e31e725f",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "94017a46-c8fe-42ef-831c-32a25aff4713",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "a4f0bac7-ad14-4ec3-a49a-51f7be16dd65",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "b591da9d-66b3-4817-8ce6-fe3c6fa9b486",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "d47ed189-3e08-4c2d-ad1d-960c86294ca5",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "e0990d80-a5ca-4254-b207-188bb587c900",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "f6120b5c-a916-44f1-bf35-40f184f3b68f",
            entityId: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
        ],
        id: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        matchValue: "35488510885",
        positionX: 403,
        positionY: 1336,
      },
      {
        displayValue: "JOSE FREDERICO CIMINO MANSSUR",
        entityIdentification: "lit.lawyer",
        extraParams: [
          {
            id: "0ee91d18-71a2-468c-82c8-98f815d0d800",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "1160d381-a511-41a9-aafe-94f42d9f845f",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "157ed72a-8c9a-4e1a-801c-bf823714b404",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "1a20c262-d768-4a70-902b-4ecdf415e3eb",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "2a806e89-702d-48a0-9707-088c5db06537",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "2ae6063b-b545-4a0b-94ad-7d0751ed43c9",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "3ab79850-fe0c-4934-84f5-2ce3b1c4ad58",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "3d232716-6cb0-4eae-a109-86966b5975ec",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "4610a499-fdc9-461f-abe7-6c9156d6463c",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "7ae8009b-f75b-4414-820e-ba1ee0bc174b",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "7e818767-0c80-48a1-b0b6-5c4dce496df1",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "oabState",
            displayValue: "Estado OAB",
            value: "SP",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "8eff310b-f8cb-4b6d-b32f-0524f8908a51",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "954e7f10-0a3c-4742-81b7-22c70f89daa7",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "oabNumber",
            displayValue: "Número OAB",
            value: "194746",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "a05053a8-aa6d-45d9-b946-a1fec21ab7ef",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "ad27ba2a-cfe9-4a14-8bcb-f56c3f7a8dd1",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "c37f67cb-a413-433d-85c5-128a52a90768",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "c5b9ee9a-9ee7-44fd-bab8-09b6a2122a1b",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "cd298598-259c-49c3-a8d3-95f8c22c56f5",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "fa2829ab-1cac-4782-a4b9-50379dbf2430",
            entityId: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
        ],
        id: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
        matchValue: "28389656892",
        positionX: 1153,
        positionY: 946,
      },
      {
        displayValue: "MIN PAULO DE TARSO SANSEVERINO TERCEIRA TURMA",
        entityIdentification: "lit.unknown",
        extraParams: [],
        id: "07a34952-f818-483f-8cee-cad5a014dc1f",
        matchValue: "",
        positionX: 1153,
        positionY: 1066,
      },
      {
        displayValue: "PATRI SEIS EMPREENDIMENTOS IMOBILIARIOS LTDA",
        entityIdentification: "lit.company",
        extraParams: [
          {
            id: "037f79d8-52a8-482a-9e6d-93559997fdbb",
            entityId: "0b7946e9-283e-44db-86cc-5df8c00bce1e",
            paramId: "companySize",
            displayValue: "Porte",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "1d75d948-75ee-4400-9b82-7fe18efdf9d7",
            entityId: "0b7946e9-283e-44db-86cc-5df8c00bce1e",
            paramId: "fundationDate",
            displayValue: "Data de Fundação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "3e771657-03fb-4202-874f-1034ce2b2178",
            entityId: "0b7946e9-283e-44db-86cc-5df8c00bce1e",
            paramId: "cnae",
            displayValue: "CNAE",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "40fc235a-46ad-4be2-a35f-e7c47303830d",
            entityId: "0b7946e9-283e-44db-86cc-5df8c00bce1e",
            paramId: "situationDate",
            displayValue: "Data Situação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "6c98e76c-ddc7-40c8-ae6b-66a05fb27ce2",
            entityId: "0b7946e9-283e-44db-86cc-5df8c00bce1e",
            paramId: "cnaeDescription",
            displayValue: "Descrição CNAE",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "932d9e1d-12d2-4589-89f9-3e1e0ebffc3c",
            entityId: "0b7946e9-283e-44db-86cc-5df8c00bce1e",
            paramId: "companyType",
            displayValue: "Tipo de Empresa",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "974bac79-d4e9-451e-a0f8-3aa6a5e00c75",
            entityId: "0b7946e9-283e-44db-86cc-5df8c00bce1e",
            paramId: "situationRazon",
            displayValue: "Motivo Situação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "a4aaa2fb-f84b-4547-b43b-83934887ac18",
            entityId: "0b7946e9-283e-44db-86cc-5df8c00bce1e",
            paramId: "situation",
            displayValue: "Situação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "c3be9ea6-2a96-4bbb-b995-9a89f3346b12",
            entityId: "0b7946e9-283e-44db-86cc-5df8c00bce1e",
            paramId: "fancyName",
            displayValue: "Nome Fantasia",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
        ],
        id: "0b7946e9-283e-44db-86cc-5df8c00bce1e",
        matchValue: "09125365000117",
        positionX: 853,
        positionY: 706,
      },
      {
        displayValue: "JOSE FREDERICO CIMINO MANSSUR",
        entityIdentification: "lit.lawyer",
        extraParams: [
          {
            id: "0359df6a-7790-415c-9bef-02bdf0759379",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "06ba9543-8814-4910-b3d3-d3d25ee4746c",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "0d487936-aa49-4a94-8ea9-b86422db5ce2",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "0e598ab8-2372-4797-be2c-2f50778514f3",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "1edacf66-2fc1-41e2-9b57-6526979e30df",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "oabNumber",
            displayValue: "Número OAB",
            value: "194746",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "3facc1bd-fbb9-4084-af77-2b3bf6793a23",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "4755a3d5-3abc-4a6c-92dc-276cfb74bf76",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "57bb57ed-f56b-48f9-bf67-c314c81b3a63",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "7a72261a-e04b-492a-9ec6-4aef8ff15649",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "81b305cf-8c53-4545-bbed-e4a77e7368ad",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "90d4a414-7e05-4291-a33e-b8f6d77d8af1",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "9858744f-0d69-479e-8588-7c99c9b9b040",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "9fa9f758-096a-4661-925d-edb835e9ab34",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "a264956c-e838-4fef-91aa-bc204f1d199e",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "a365332f-300e-462a-8672-386d8733382e",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "a7319423-881a-48e1-8a56-fa190fc1b1b6",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "abbce7f5-64a4-407b-9cca-43764f1401eb",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "be8d75f9-ef95-4e13-a1cc-629f7e804347",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "oabState",
            displayValue: "Estado OAB",
            value: "SP",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "fc33782d-46d6-4124-a8d7-3821d3703b68",
            entityId: "109ca169-4643-4854-8a6c-7331dfee98b3",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
        ],
        id: "109ca169-4643-4854-8a6c-7331dfee98b3",
        matchValue: "28389656892",
        positionX: 853,
        positionY: 826,
      },
      {
        displayValue: "PAULA ROBERTA DE MORAES SILVA",
        entityIdentification: "lit.lawyer",
        extraParams: [
          {
            id: "05b20f8f-cc56-499b-ac15-743a42b63778",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "0db762d9-2c17-4ce1-8572-c1d94596d098",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "346d0bae-4954-4159-8ef8-f455c7cb7376",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "3813d4ef-6057-4d2f-93bd-c1448fc241cb",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "39305902-25e7-4bec-a6fb-9df44e824565",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "39e1df36-d71a-4457-8d0e-28b320bc7635",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "41d6241f-e34d-4f41-9f86-df45476d34c4",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "oabState",
            displayValue: "Estado OAB",
            value: "SP",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "48be3061-771b-45c2-9390-ce1e7cbcc500",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "503895d4-0904-4a86-95d8-11af2acadf37",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "556a4b15-0893-4f98-9a53-28046cd879db",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "63a27a12-7ed5-459f-9500-0c2c102b58e7",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "7cdf91ac-7983-491d-b15f-d42782ef3339",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "88d51191-1c40-4eb6-9d1b-a9fcc13b5c96",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "97ca2fb1-ca60-40fc-a0cd-2439cf387c8a",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "abaca62d-0118-4b7a-809e-06eb34edaac6",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "oabNumber",
            displayValue: "Número OAB",
            value: "315989",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "c37537b0-dd85-4841-9900-d1e45b02c233",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "d6a25b4e-2fe2-406b-9f63-b6bd181937b2",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "eeaf687f-f8ea-41b3-b9ff-7a6702353381",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "fe2765b9-7259-4eab-a16e-72502c002942",
            entityId: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
        ],
        id: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
        matchValue: "35876422819",
        positionX: -47,
        positionY: 946,
      },
      {
        displayValue: "IRACEMA DE CARVALHO LEAL",
        entityIdentification: "lit.person",
        extraParams: [
          {
            id: "068cd034-2da0-4c1a-953c-971f15314db8",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "1d701e91-4149-42d1-a7b3-d97132eb9ddc",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "2d4665be-c57f-4f7f-ad24-783f12d50d22",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "408949cb-b0b8-4ee8-ba71-4301c4ec4cf4",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "5b62256e-753b-402e-bb9e-745bcc33ce3b",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "6de3de10-142d-4c77-a5fa-90f1f9267f75",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "6f8e77c7-85ec-457d-a512-22e31cb73fa9",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "7ac1f1eb-dcd9-4654-a657-0ece6c3a4da7",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "7effcf96-8287-4c84-b79b-29d5cd4e3904",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "803f9524-359e-46b5-a67f-c8b5e01922f3",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "8465adf6-14f2-4828-ad93-5d1f66f8d884",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "961c912a-c3c0-496a-98f5-1f1132cf2575",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "9632aa3a-d2a5-4109-af13-cfe1b28ce8e3",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "9b3d4d39-2ba9-4ef8-ac76-d2199582e968",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "ab6b71ae-3650-43a6-be8c-14fc947ddfe1",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "ad7c4070-b229-4ceb-9a3f-7d7669f01360",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "dfd47fb5-bd08-4b56-9c73-cab01a1d5ba3",
            entityId: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
        ],
        id: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
        matchValue: "31994727888",
        positionX: 253,
        positionY: 1696,
      },
      {
        displayValue: "PAULA ROBERTA DE MORAES SILVA",
        entityIdentification: "lit.company",
        extraParams: [
          {
            id: "0c89e7a7-4a2a-4c6c-8b04-757300c9ea3a",
            entityId: "33c9ef16-9646-4fae-a1bb-986ce25abc12",
            paramId: "companyType",
            displayValue: "Tipo de Empresa",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "37c8b12f-fed1-40f7-8a56-67ae3ea8160c",
            entityId: "33c9ef16-9646-4fae-a1bb-986ce25abc12",
            paramId: "situationDate",
            displayValue: "Data Situação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "4ef49dff-c64c-4ca0-aa42-f8fe668240f0",
            entityId: "33c9ef16-9646-4fae-a1bb-986ce25abc12",
            paramId: "fundationDate",
            displayValue: "Data de Fundação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "6f0cad5f-efa4-4a82-83ec-560d6f9df41e",
            entityId: "33c9ef16-9646-4fae-a1bb-986ce25abc12",
            paramId: "cnaeDescription",
            displayValue: "Descrição CNAE",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "8324884b-23ab-443b-9a20-63191c6dcac6",
            entityId: "33c9ef16-9646-4fae-a1bb-986ce25abc12",
            paramId: "situationRazon",
            displayValue: "Motivo Situação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "ba418975-f476-4df1-9a83-8e0bbf746dd1",
            entityId: "33c9ef16-9646-4fae-a1bb-986ce25abc12",
            paramId: "fancyName",
            displayValue: "Nome Fantasia",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "bd047eac-747a-4914-b969-8db20d980ed4",
            entityId: "33c9ef16-9646-4fae-a1bb-986ce25abc12",
            paramId: "cnae",
            displayValue: "CNAE",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "d61c4eaf-9225-48ef-8809-c2e4db38f553",
            entityId: "33c9ef16-9646-4fae-a1bb-986ce25abc12",
            paramId: "situation",
            displayValue: "Situação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "f8b30210-5d73-412b-ad3b-d69141cb4ad4",
            entityId: "33c9ef16-9646-4fae-a1bb-986ce25abc12",
            paramId: "companySize",
            displayValue: "Porte",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
        ],
        id: "33c9ef16-9646-4fae-a1bb-986ce25abc12",
        matchValue: "28283667000168",
        positionX: 253,
        positionY: 706,
      },
      {
        displayValue: "00013436320184036181",
        entityIdentification: "lit.lawsuit",
        extraParams: [],
        id: "34e39c5c-46cd-448f-841a-9d61020c68fb",
        matchValue: "00013436320184036181",
        positionX: 853,
        positionY: 1456,
      },
      {
        displayValue: "MARILIA MICKEL MIYAMOTO NALETTO TEIXEIRA",
        entityIdentification: "lit.lawyer",
        extraParams: [
          {
            id: "053f2d56-e9c3-4ec7-9d6e-c00616926ec8",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "280518ef-cb71-4e4b-9997-b8fa88f60430",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "2ac129d9-5867-4030-9ee9-f2e2d849046f",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "30effd1e-5cfd-4c77-8f91-18310ff4ae30",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "36a2607a-f5fa-4fa3-8804-f6798ae2d964",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "46ca6811-01bf-4a5c-8bfe-278c78984db2",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "5955327f-933c-4665-8fe4-17be354e113f",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "733b1fef-1f6b-40bc-b1c9-c5be62513627",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "8335a82e-78f5-4bb4-be42-a91c96108986",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "933d21fe-becf-4117-9561-32b6d9af4786",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "9d969946-adae-467a-a7da-7242886c1739",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "oabState",
            displayValue: "Estado OAB",
            value: "SP",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "ac20f71d-5b3d-41e6-9741-00943a709432",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "ae739edf-12c2-48d6-b842-7ba86478a9c2",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "bcfaa8d7-d3e1-46d8-a855-95141b4803b0",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "c06eb037-bbee-4c5c-86d4-0b8d7acec2e5",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "oabNumber",
            displayValue: "Número OAB",
            value: "271431",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "cc2c9d20-861f-48c8-b2a2-6ef0a338c0b8",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "d13ef8cd-51b1-4061-94ff-d6e5473fea49",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "e0ba2134-1e86-45c4-8645-6452ac55527b",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "ec1904fd-e333-4105-bfc5-08362f8e7d27",
            entityId: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
        ],
        id: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
        matchValue: "22291945858",
        positionX: 28,
        positionY: 1966,
      },
      {
        displayValue: "00189403820138260008",
        entityIdentification: "lit.lawsuit",
        extraParams: [],
        id: "387c9439-29ba-4386-a01a-f546dc596718",
        matchValue: "00189403820138260008",
        positionX: -47,
        positionY: 1456,
      },
      {
        displayValue: "PAULA ROBERTA DE MORAES SILVA",
        entityIdentification: "lit.company",
        extraParams: [
          {
            id: "06e668eb-5941-45ab-a97e-69de6a452ae4",
            entityId: "38882acc-0092-422f-b3b2-9a403d83e3a2",
            paramId: "fancyName",
            displayValue: "Nome Fantasia",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "13c890a6-4685-4683-bed3-73b76a0b8153",
            entityId: "38882acc-0092-422f-b3b2-9a403d83e3a2",
            paramId: "companyType",
            displayValue: "Tipo de Empresa",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "195a21a3-e582-49b2-8021-8364d6853ca7",
            entityId: "38882acc-0092-422f-b3b2-9a403d83e3a2",
            paramId: "situation",
            displayValue: "Situação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "1e07399c-e3c3-4778-8211-0e83a9501062",
            entityId: "38882acc-0092-422f-b3b2-9a403d83e3a2",
            paramId: "fundationDate",
            displayValue: "Data de Fundação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "519ea448-666c-4287-8325-948cdbd4d2fc",
            entityId: "38882acc-0092-422f-b3b2-9a403d83e3a2",
            paramId: "situationRazon",
            displayValue: "Motivo Situação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "5fae1b30-f673-458e-8f38-5b490f144950",
            entityId: "38882acc-0092-422f-b3b2-9a403d83e3a2",
            paramId: "situationDate",
            displayValue: "Data Situação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "84f87027-3ebc-4bbd-b8f3-37c32cf7ce75",
            entityId: "38882acc-0092-422f-b3b2-9a403d83e3a2",
            paramId: "cnaeDescription",
            displayValue: "Descrição CNAE",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "c1b6cf1f-0b90-4bca-882b-35a2f6827356",
            entityId: "38882acc-0092-422f-b3b2-9a403d83e3a2",
            paramId: "companySize",
            displayValue: "Porte",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "e3051a9b-0d26-4fdf-bff1-682560ec861d",
            entityId: "38882acc-0092-422f-b3b2-9a403d83e3a2",
            paramId: "cnae",
            displayValue: "CNAE",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
        ],
        id: "38882acc-0092-422f-b3b2-9a403d83e3a2",
        matchValue: "28283667000168",
        positionX: -47,
        positionY: 706,
      },
      {
        displayValue: "JORGE YAMANISKI FILHO",
        entityIdentification: "lit.lawyer",
        extraParams: [
          {
            id: "03081b1c-1755-43e1-a66c-41a4bdff122c",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "11eccd30-6d49-4ed9-9efa-74ccddbd4589",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "154ea63e-bfa4-4553-baee-48543e7b045a",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "203d9904-3f95-4a08-9288-45da199db247",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "2348b0a1-4fa1-4d4a-8a77-c3bbdb8c7d7a",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "27fecb51-cdd2-409a-af1d-a8294b179ac1",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "39271ed0-7a27-43d3-9f05-d669b9737d4d",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "oabState",
            displayValue: "Estado OAB",
            value: "SP",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "40daaf2a-4a24-4b03-9463-0b1ea6994f13",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "4a6be7f9-1913-43f1-a07f-1e7a81f94f08",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "61c8d1e4-6cd5-4683-8046-65b8378f851e",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "64e802f5-68fd-4015-8c2a-e1112e951802",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "oabNumber",
            displayValue: "Número OAB",
            value: "68997",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "65e5d872-13f4-4fbc-a2b0-5fff60bec734",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "7885b4e3-df76-4a1d-9eb6-aaec7160e0b2",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "90c79f36-6313-4fc6-ba0a-adcd736e7390",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "afa7874b-d99d-4172-baf3-ae087b75cf10",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "b5b82261-6668-4bdd-9173-0e3d911bd2ba",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "cc2adceb-3aeb-46c1-82af-30c267f0c8b4",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "ceae3871-2b0d-46b7-99dc-92ea77adc449",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "eaed4737-6099-495a-84c1-27493cbd084f",
            entityId: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
        ],
        id: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
        matchValue: "01225571820",
        positionX: 853,
        positionY: 946,
      },
      {
        displayValue: "00164175320138260008",
        entityIdentification: "lit.lawsuit",
        extraParams: [],
        id: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        matchValue: "00164175320138260008",
        positionX: 403,
        positionY: 586,
      },
      {
        displayValue: "DONEGA MORANDINI",
        entityIdentification: "lit.unknown",
        extraParams: [],
        id: "4b1b997c-2b61-42ac-88d5-b991537ba0a4",
        matchValue: "",
        positionX: -47,
        positionY: 1186,
      },
      {
        displayValue: "MARCIA CARDOSO",
        entityIdentification: "lit.unknown",
        extraParams: [],
        id: "5025cb3b-2a3a-4a27-8679-0f07718996cc",
        matchValue: "",
        positionX: -347,
        positionY: 1186,
      },
      {
        displayValue: "PAULA MARQUES RODRIGUES",
        entityIdentification: "lit.lawyer",
        extraParams: [
          {
            id: "0aa4879f-5f58-481d-86a1-55576dba3e67",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "0de60c68-62f9-49f6-9dc0-58e4bf31d1e3",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "1bef5253-48ac-4d11-96fc-4cd6304a0201",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "2bf3ca1d-7bbe-44ac-8dcb-9a723b318654",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "39e53a48-25f9-44c8-ae32-2ea907530026",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "4d64e329-40d6-453d-80f6-e078bf5e2c21",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "6bbf1b06-db60-471c-a4ea-46bdaf329c28",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "7cca87ce-dea6-4571-9ad1-9957c682780f",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "9dc06d7d-642c-4dcc-980e-b19dd6aee338",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "9e3f19e0-4881-4804-9455-318352fedda9",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "a1b78a90-72c2-4c4c-9bff-6f853f2f565b",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "a95a41a4-7c9f-4ee1-8342-1a4118abe04f",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "aac4d577-1352-462d-87a3-2e3be3f6bb90",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "oabState",
            displayValue: "Estado OAB",
            value: "SP",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "b487bf18-c544-45a4-9a2a-a9334e03e657",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "c65caf2a-fb14-4e84-a9e3-b6468baab08f",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "cea65def-5e1e-48d2-8a0c-110d369eaed9",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "cf94e6e7-783c-46ba-8e5a-464343ba5365",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "eb9c0b1b-55a3-46e1-b7a9-f3a1cd1b2e8f",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "oabNumber",
            displayValue: "Número OAB",
            value: "301179",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "fba6bb5a-4ffc-4744-b413-7274f98e9eb6",
            entityId: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
        ],
        id: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
        matchValue: "33167673800",
        positionX: -47,
        positionY: 826,
      },
      {
        displayValue: "LUCIANE CRISTINA SILVA TAVARES",
        entityIdentification: "lit.person",
        extraParams: [
          {
            id: "0d64856c-4b8b-4abb-9c2b-e74ccf6053f9",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "0f1ffda2-3c23-47c4-a024-88f4f83e76e0",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "1b52f1ac-cae8-4ccf-8bd6-3411c9003c00",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "1e1965ca-ee68-4c2b-afff-afc9fab6d1e6",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "37d7e264-9dad-4952-be04-55113c855458",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "4e864437-a084-40b6-8216-1dee94301acc",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "5f6d8974-8aee-47c1-926b-d4b533175832",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "6d3a477d-d403-4ce4-a904-c9cacca488ce",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "98585ff7-8b63-4a62-956b-17ac757c5177",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "ac687e3a-6727-4b4e-923c-4d569e3e1266",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "b93199a8-e269-4442-abc6-929c6cd865a1",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "d0cffe93-9415-471b-b671-8f6d672f9e06",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "d7036c29-7262-48e4-afa7-bc942ca9a2ed",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "e96dadec-da82-4ff4-b3c9-9651fae7ce35",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "ec34f5a1-f3c0-4e94-bd35-3f96650fd9be",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "f8cf90f0-d93c-484b-87db-d8f2db31aadb",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "fb8e4ba7-2ac6-4ebc-aad0-b6646a2dcbbd",
            entityId: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
        ],
        id: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
        matchValue: "27517665871",
        positionX: 628,
        positionY: 1966,
      },
      {
        displayValue: "PATRI SEIS EMPREENDIMENTOS IMOBILIARIOS LTDA",
        entityIdentification: "lit.company",
        extraParams: [
          {
            id: "023f3168-2200-484a-93dd-0bcdd47852ee",
            entityId: "61bb4d0c-560f-409f-9557-119880c17c65",
            paramId: "fancyName",
            displayValue: "Nome Fantasia",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "0b40eea6-11ae-47a5-81fd-44766badd13f",
            entityId: "61bb4d0c-560f-409f-9557-119880c17c65",
            paramId: "cnaeDescription",
            displayValue: "Descrição CNAE",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "11abbcfa-1963-4821-a0ce-b142284c8f5c",
            entityId: "61bb4d0c-560f-409f-9557-119880c17c65",
            paramId: "situationDate",
            displayValue: "Data Situação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "1dd245c9-46f2-46b9-bc23-0f1f8905b6fd",
            entityId: "61bb4d0c-560f-409f-9557-119880c17c65",
            paramId: "companyType",
            displayValue: "Tipo de Empresa",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "3b9495b4-4935-434a-b6e2-101e06ef8e67",
            entityId: "61bb4d0c-560f-409f-9557-119880c17c65",
            paramId: "companySize",
            displayValue: "Porte",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "6613e58a-e2aa-443e-9027-f981d0daad1c",
            entityId: "61bb4d0c-560f-409f-9557-119880c17c65",
            paramId: "fundationDate",
            displayValue: "Data de Fundação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "6d609ef0-3e21-4bb3-b70b-3514df4d10a0",
            entityId: "61bb4d0c-560f-409f-9557-119880c17c65",
            paramId: "situationRazon",
            displayValue: "Motivo Situação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "a675b504-b4a8-4f42-93c4-389c62a61b35",
            entityId: "61bb4d0c-560f-409f-9557-119880c17c65",
            paramId: "situation",
            displayValue: "Situação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "cf5e1f79-1277-4e92-adbf-ae4903f31080",
            entityId: "61bb4d0c-560f-409f-9557-119880c17c65",
            paramId: "cnae",
            displayValue: "CNAE",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
        ],
        id: "61bb4d0c-560f-409f-9557-119880c17c65",
        matchValue: "09125365000117",
        positionX: 553,
        positionY: 706,
      },
      {
        displayValue: "DECOLAR COM LTDA",
        entityIdentification: "lit.unknown",
        extraParams: [],
        id: "66cebb63-427c-4f94-b6b4-e8362f1bc6f4",
        matchValue: "",
        positionX: 928,
        positionY: 1966,
      },
      {
        displayValue: "PATRI SEIS EMPREENDIMENTOS IMOBILIARIOS LTDA",
        entityIdentification: "lit.company",
        extraParams: [
          {
            id: "30398da9-f002-44d7-8507-5dcb92fd08a2",
            entityId: "6fc0e746-e9f7-4034-9532-d693ab81895c",
            paramId: "fancyName",
            displayValue: "Nome Fantasia",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "36013acd-3ecd-4178-bf5f-d33459bc3ed0",
            entityId: "6fc0e746-e9f7-4034-9532-d693ab81895c",
            paramId: "cnaeDescription",
            displayValue: "Descrição CNAE",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "51f4e850-4ce0-4b2e-be61-819fc9094070",
            entityId: "6fc0e746-e9f7-4034-9532-d693ab81895c",
            paramId: "situation",
            displayValue: "Situação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "8f04eacc-7c58-45f4-9149-006666772e13",
            entityId: "6fc0e746-e9f7-4034-9532-d693ab81895c",
            paramId: "companySize",
            displayValue: "Porte",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "90cb336e-4829-4caf-b548-7148571d20c9",
            entityId: "6fc0e746-e9f7-4034-9532-d693ab81895c",
            paramId: "fundationDate",
            displayValue: "Data de Fundação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "a12b9813-3d1e-4211-a549-e048a7c427b9",
            entityId: "6fc0e746-e9f7-4034-9532-d693ab81895c",
            paramId: "situationDate",
            displayValue: "Data Situação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "ae2cb618-c936-4863-9328-3fec4f1c2655",
            entityId: "6fc0e746-e9f7-4034-9532-d693ab81895c",
            paramId: "companyType",
            displayValue: "Tipo de Empresa",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "d5d17f14-1dce-4913-996b-3b496e33d0bf",
            entityId: "6fc0e746-e9f7-4034-9532-d693ab81895c",
            paramId: "cnae",
            displayValue: "CNAE",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "ee17d49e-56a1-49ec-85b7-4a3f4458c200",
            entityId: "6fc0e746-e9f7-4034-9532-d693ab81895c",
            paramId: "situationRazon",
            displayValue: "Motivo Situação",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
        ],
        id: "6fc0e746-e9f7-4034-9532-d693ab81895c",
        matchValue: "09125365000117",
        positionX: -347,
        positionY: 706,
      },
      {
        displayValue: "10105332120168260008",
        entityIdentification: "lit.lawsuit",
        extraParams: [],
        id: "722bfa1b-d0d5-4803-9f26-dbe0b52372d7",
        matchValue: "10105332120168260008",
        positionX: 478,
        positionY: 1846,
      },
      {
        displayValue: "MARCELLA SIQUEIRA SEGURO LEAL",
        entityIdentification: "lit.person",
        extraParams: [
          {
            id: "03945e0c-6b18-4498-914d-2ce24c08176c",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "19b1a56e-acfd-4ea8-ad1d-296adc28e7d5",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "1f314aa7-ab3c-4a45-8fa9-e25c47ad5de9",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "2943911d-6354-4b1b-84fd-9d1898a66fbd",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "2daf8717-d3ce-4329-b45b-90c7ace0128b",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "449c2d04-0a01-43c9-b00e-2a2885bf94d0",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "480e774a-c322-48e0-a121-2fc931f0b2e2",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "504b2783-2e8b-4c23-8041-09cff0d1a6aa",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "74776b68-ba5d-496a-a315-3b94f5108be5",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "82e29a07-7c2d-4cfe-9131-7b524552770d",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "8de2c111-eb0a-48b8-99d4-e01836254df6",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "99d8749b-cb97-449f-bcc5-e488407d91d0",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "99f1c50f-a791-46a1-a34e-b4bb81ad76b8",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "c9f7e473-8a58-4643-b980-96b3b34871af",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "cdb9da47-f0c8-446f-b474-cb6fa114754a",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "e06d31a4-e922-4b51-9fe5-9404c64cf02d",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "ff6c8419-bed4-4da6-8816-76dde0d73eaf",
            entityId: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
        ],
        id: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
        matchValue: "10624079708",
        positionX: 853,
        positionY: 1066,
      },
      {
        displayValue: "MARCELLA SIQUEIRA SEGURO LEAL",
        entityIdentification: "lit.person",
        extraParams: [
          {
            id: "07b02b61-5c5e-4c29-be14-a07f51d3465c",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "322f4c1e-ddd5-488c-873f-743c290f948b",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "4e7fe084-faa2-451a-a77d-babad3a668d3",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "512d7bd3-399d-4bde-9b49-a1a30b699b73",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "5bedadf8-04bb-4092-98f7-c66e537292c6",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "61d527ef-1c03-4535-bd6b-75eecfba20c9",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "62b95e08-f1b8-403b-b001-229ee00159d9",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "72b96bcb-ea0b-4bf7-8175-0c8dff83257b",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "814f64fc-2023-4ddb-93ef-d59d6746251c",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "84e4a7fd-a610-46e8-a1bb-1136496e49bc",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "d1918b56-c8b1-4fc2-8885-1f122ad16bd7",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "dfcfa13c-a056-407a-b427-864cdc199151",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "e03c4c8c-2127-4af8-8da6-ca3da7c17f2d",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "e63d9437-e43b-4345-8312-a9083bdc8746",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "f0a46442-c2fa-4fe5-8194-078482c93784",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "f29aa242-0c4d-48e8-9aa9-ca66f9fb5ae9",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "f5944700-bef9-42d3-ada8-fc7d91a2d332",
            entityId: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
        ],
        id: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
        matchValue: "10624079708",
        positionX: 553,
        positionY: 1066,
      },
      {
        displayValue: "DENISE DE CARVALHO LEAL LOZZI",
        entityIdentification: "lit.person",
        extraParams: [
          {
            id: "10d0ab66-897d-40c1-8fd2-d756aff0084e",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "27091195-8aaf-403b-938b-5948086d1a42",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "396cab25-1261-42fd-b000-ebe0ab2b6096",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "48b1fdbc-1ac5-4edd-afe5-29cfc10bbc2d",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "4d7578ea-ae7c-4bae-bf25-5f014ae60dfd",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "52719422-2b35-4406-9a1e-41d6ba9644dd",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "56884294-e341-49d9-95cc-f53a65ab9d73",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "6da35792-c742-4eab-a476-0f2d21e6ee56",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "7d587066-6387-4409-95a5-ab4b4eaaa265",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "910f9f24-6826-43e0-950c-8ca579a1dffb",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "ae11b3b0-72a0-4ac1-a077-57fc8a0ae8c5",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "c4018ff4-3b7f-4584-a698-328bb7dbf69c",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "d7e1198b-e2cf-40b4-8937-75305c094493",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "dd33f9ff-a8a2-4974-8443-f1a6d42844b7",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "e6c96780-9f90-4e02-8451-604fea5a257b",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "eb1b83e2-b722-4f68-bdbe-09bc8afebfcc",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "ed9cb12b-d7cd-4e50-82da-507a305182c0",
            entityId: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
        ],
        id: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
        matchValue: "38986499894",
        positionX: -122,
        positionY: 1846,
      },
      {
        displayValue: "00164175320138260008",
        entityIdentification: "lit.lawsuit",
        extraParams: [],
        id: "78a7528e-6473-4c44-81af-803e4b57e8e4",
        matchValue: "00164175320138260008",
        positionX: 853,
        positionY: 1576,
      },
      {
        displayValue: "JORGE YAMANISKI FILHO",
        entityIdentification: "lit.lawyer",
        extraParams: [
          {
            id: "08c7717f-4301-489b-b5e7-53c8653a4b4c",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "099cbda4-2b1c-44cc-bf15-1e516614e3f9",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "0a72ebc6-2d7e-4c25-b3c6-ddc920e5b884",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "11dac025-4fa2-4e81-b568-7d2a2e091498",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "24da2948-e01c-4fd8-9da4-7cfa99a5fc9f",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "2b410013-df0b-4190-8a7b-08bd5ecf0192",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "oabState",
            displayValue: "Estado OAB",
            value: "SP",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "5c94bf45-19b8-4586-a4a8-00aee9c307f8",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "5ec6c61c-d0ae-43e5-aff3-22b65d236e6b",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "6de26ef1-8482-4f91-9756-8d2d7f94cab6",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "77fd9d9d-9c5d-423d-9c10-f48723f5d984",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "874736d9-c5b3-41ac-97e5-3de7ca7e91ab",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "93582507-5dd2-445d-accc-0a6928710f1d",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "b93ebf1f-9195-4265-b7d4-e3db5dc2ea71",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "oabNumber",
            displayValue: "Número OAB",
            value: "68997",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "bd9580d9-32bf-458a-b3c8-8481abbe6af1",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "c87397da-d99a-4413-98a1-1acddb8ef193",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "f1ff3586-fbf2-41dd-aac8-2367229d7dc1",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "f5ff2e44-b350-4267-81f1-0d93e905ffd9",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "f6199432-69e5-4444-af5f-c79b8a014483",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "fd96162c-cab7-4508-bab1-516d002b7d45",
            entityId: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
        ],
        id: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
        matchValue: "01225571820",
        positionX: 553,
        positionY: 946,
      },
      {
        displayValue: "50166894820194030000",
        entityIdentification: "lit.lawsuit",
        extraParams: [],
        id: "91081f3b-f1c1-47f0-a87f-c702b095b04a",
        matchValue: "50166894820194030000",
        positionX: 253,
        positionY: 1576,
      },
      {
        displayValue: "00026154120208260008",
        entityIdentification: "lit.lawsuit",
        extraParams: [],
        id: "a2538866-d654-4563-ae4f-6524cb640510",
        matchValue: "00026154120208260008",
        positionX: 553,
        positionY: 1456,
      },
      {
        displayValue: "50152596120194030000",
        entityIdentification: "lit.lawsuit",
        extraParams: [],
        id: "ab801173-63c7-4b20-90a1-bb6955b4bdf9",
        matchValue: "50152596120194030000",
        positionX: 553,
        positionY: 1576,
      },
      {
        displayValue: "20282975120218260000",
        entityIdentification: "lit.lawsuit",
        extraParams: [],
        id: "ae387334-1270-4d82-9d7b-4c9ca4f704dc",
        matchValue: "20282975120218260000",
        positionX: -47,
        positionY: 1576,
      },
      {
        displayValue: "MARCELLA SIQUEIRA SEGURO LEAL",
        entityIdentification: "lit.person",
        extraParams: [
          {
            id: "0b174021-74d0-4bd8-856e-eb268e314687",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "1b410ccc-695a-448c-a6fb-186307b3d2f9",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "2ca5c86a-212a-4bad-98e0-c8012cb28679",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "2f76f724-3ccd-448f-be8a-a56e9aeab122",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "31555bed-b0d2-42c3-aee0-434b3641f9c1",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "50bb2f8f-ea03-489f-a154-77c4700ded6d",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "5615d599-f70c-4089-a0a9-913f1cfdbb41",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "64a3aadf-cda8-42d4-96fc-94246100344a",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "6abea98a-3430-470b-b489-15f2d10c465b",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "799177ed-7d06-4227-8167-de7fb94f87f5",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "9fbe5ffa-de82-46b1-9690-c61cf25d5ee4",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "aa22738d-294a-4b4c-a847-89b10a9c08c8",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "b78d9c20-77cc-4b0d-bbfc-002e7f99ff09",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "b841b3f6-1b61-47f1-988a-f30a6a2f5ce4",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "d0bbd917-87c6-4dfb-8d07-b39497319992",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "e19242c0-23d7-4960-9e9e-bd31e2814ae7",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "ef4c6de1-0d42-499a-b80a-fdcee92ec253",
            entityId: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
        ],
        id: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
        matchValue: "10624079708",
        positionX: 253,
        positionY: 1066,
      },
      {
        displayValue: "PAULA ROBERTA DE MORAES SILVA",
        entityIdentification: "lit.lawyer",
        extraParams: [
          {
            id: "087288c3-614e-45a0-8911-608e920ce78e",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "0a44c9dd-05e9-4242-ad8e-8c76118c28d8",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "12e971e7-0bce-4708-9d12-ff15adb17627",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "2e6136af-a9c1-4045-a02e-57894e855647",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "oabNumber",
            displayValue: "Número OAB",
            value: "315989",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "3e1a936d-5504-42f2-9331-e39441419827",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "431ec997-ec32-43e8-a1c7-8971f01e8c5e",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "4653c5e9-d759-4e44-875a-f5428ab26fd3",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "4800a278-c286-4116-952a-a64f2c5efcc5",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "77f71ab3-10af-4912-8f3f-58eed6d77b42",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "78af35d0-9b68-4a55-8db1-978c2dbe1765",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "90d2dd4e-a111-415d-af4c-7e4103c29312",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "950193fe-83d6-455b-91fc-073b470486eb",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "oabState",
            displayValue: "Estado OAB",
            value: "SP",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "951876c8-7b2c-4357-9c96-7cae2b9e0257",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "a5f8e975-7610-4ea6-8f12-c1e317167bd9",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "abfb4e46-f3a1-4c68-b255-c46abaeca2f8",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "bc15a00d-cec5-4357-88de-208e64c93780",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "c0debe67-4b76-4cc0-aea4-b8b98de31a9e",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "dbfb36ca-a043-4eab-b28c-753eebe8d147",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "f1699e07-a656-43da-b6fb-3d148c4aba68",
            entityId: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
        ],
        id: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
        matchValue: "35876422819",
        positionX: 1153,
        positionY: 826,
      },
      {
        displayValue: "OSMAR ALVES DE CAMPOS GOLEGA NETO",
        entityIdentification: "lit.lawyer",
        extraParams: [
          {
            id: "07af600f-e3a9-4418-b195-7abecce4f06d",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "126f235f-10b8-4e3b-b846-217c2cfaf4ed",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "oabState",
            displayValue: "Estado OAB",
            value: "SP",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "225c5bbf-8a58-41df-bbff-c4de2c71eb62",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "41188592-7ae2-4f1e-acf6-481ac19d3d80",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "571e5603-ef51-4eb5-b090-4909ef5bff0b",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "5ca7a713-3a2d-469a-88ee-7fda1d2d8f4c",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "6539d774-e14d-4edf-b95a-0f52970590d3",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "69b0e930-2875-4a76-a5af-cecea218b3cc",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "7370e70e-fdca-4c10-9cae-ad8e1b93b356",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "74200291-9b11-4b00-bad3-8666acbe6264",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "75149656-4048-4080-b3d6-a4c12e57d39f",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "oabNumber",
            displayValue: "Número OAB",
            value: "277703",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "9da2efe5-a908-46fd-a187-641b5a7e5733",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "b658eb90-6fde-41d5-be52-78409c26c177",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "bbc8903b-0ea4-4bff-a72c-0d2bc546f55a",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "cb679ab7-ca89-44df-9d6f-07855fac1176",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "cd9908ee-c14f-4499-8cb4-4d6c4b4958c9",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "dbc0d0b1-3238-49c6-92c1-b805b1606b84",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "e3060201-f3dd-4323-a144-85c960645e5c",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "fa0d69e1-d92c-4dc7-ab64-7f8968bba442",
            entityId: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
        ],
        id: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
        matchValue: "32832522890",
        positionX: 1153,
        positionY: 706,
      },
      {
        displayValue: "PAULA ROBERTA DE MORAES SILVA",
        entityIdentification: "lit.lawyer",
        extraParams: [
          {
            id: "17c81544-8474-495d-a474-6fda4ce03238",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "1e5d9b65-b54a-462c-97b0-923a4c4bf89b",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "295611c7-9a4d-4a35-a969-0baa13c090db",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "2b7d5b34-8706-4e44-afb0-8ed2526f25cc",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "34ff00de-5736-4a03-b00a-3fa9ceff06a6",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "47769494-a4ec-415c-bed3-0fce4f1295d2",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "59bc603f-a529-4f5b-9db7-4c2fb76859d8",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "5aa02e4b-4d5a-487d-bc0f-194cd7f8e404",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "753e46ed-3d6a-449d-bbcd-3089d90b18d8",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "oabNumber",
            displayValue: "Número OAB",
            value: "315989",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "8c18269f-cf03-427c-93bd-e66fb9d45b58",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "8f40d941-1ef4-467f-9343-3d2d0d966ca5",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "oabState",
            displayValue: "Estado OAB",
            value: "SP",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "8f74f153-2eaa-419b-bbad-ee33792406f5",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "910fc619-2efc-4f44-9918-16234adf2cf5",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "93773fe1-030c-4057-96fb-8f69e8ecc7a7",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "a415dbc3-4955-4b8e-bb8f-332a2ebb47ab",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "a785d890-818b-43c8-ae8b-b328134208b6",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "ab76955a-4ecc-495f-982f-f149e9bd295b",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "dc110bfd-7a92-4bc1-868a-fe1843a57d9c",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "df82c0da-b1b3-479a-9392-cef0b7eca6ab",
            entityId: "bf55a677-9b4c-41c8-ab10-be92585924f6",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
        ],
        id: "bf55a677-9b4c-41c8-ab10-be92585924f6",
        matchValue: "35876422819",
        positionX: -347,
        positionY: 1066,
      },
      {
        displayValue: "JOSE FREDERICO CIMINO MANSSUR",
        entityIdentification: "lit.lawyer",
        extraParams: [
          {
            id: "0fa6da67-5a2b-4189-ab93-7f97bee35e13",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "10e0ca3a-c09a-4be2-accd-2e8cca49b3f6",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "214c091e-24a7-4af5-a244-194195989fb7",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "31f17003-3533-4a18-8dba-ca412589f90e",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "3e0f7af0-1a32-40d0-b2dd-5102464a3524",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "46ffe393-4322-4672-ba70-713f46fb6613",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "4a1aa108-5a68-4123-b9f2-aedb710fb8d4",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "4cb55849-99c5-4735-9a67-c68166c93a13",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "62f2b654-1287-4be1-abe3-04ba36326ab5",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "71ebef7b-3a39-48bd-93ad-35344031cce6",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "7f869d42-8df4-4664-b703-44450608df5e",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "8c7f7719-9dc2-4d79-acc3-380eea8bfb0c",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "ab362a87-574d-4c5e-8532-b78f79b40bed",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "c41b7f8f-85c7-496d-bca5-170808f8c77b",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "d703e3a0-345d-472a-bdd1-987de79de9b9",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "oabNumber",
            displayValue: "Número OAB",
            value: "194746",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "e666ae34-f68e-4f2d-a4b3-de777e13e519",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "e8083f20-21fd-4442-b05f-456c055e2ce6",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "e959a520-9ec4-47c0-9cf1-b09bf7bfb1c9",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "f6344911-a1fe-4649-a924-c3393da411c8",
            entityId: "c2657f40-7530-439c-b8dd-17a3913a92e5",
            paramId: "oabState",
            displayValue: "Estado OAB",
            value: "SP",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
        ],
        id: "c2657f40-7530-439c-b8dd-17a3913a92e5",
        matchValue: "28389656892",
        positionX: -347,
        positionY: 946,
      },
      {
        displayValue: "MARCELLA SIQUEIRA SEGURO LEAL",
        entityIdentification: "lit.person",
        extraParams: [
          {
            id: "13d6925c-aac5-4a95-95a2-b16a83421736",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "1463577a-93d0-471c-b68f-6bb6fc215622",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "17eacfa7-9665-4adf-9d96-2f5ec4e02090",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "31a37afe-a38f-462e-b23a-7235d2e2b80a",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "41639547-46ce-43ed-9ca9-1df639df1553",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "57877f88-0331-4990-aff1-201c53acf3df",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "5cd9342f-1a08-4ce6-93c7-5f3fe577c48d",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "802c099e-b9a2-4665-907e-3b1eae1a5e14",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "8b02ef39-7dd2-40d4-9f2c-e148d2f7c967",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "c1c26937-9c15-41e7-bdf1-b19c5aaf8cc9",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "d4e7be5d-faaf-426b-a5a3-69de393608e2",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "d80f8a33-daf8-4bdd-bb3e-fcf3198e2180",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "dcc37842-0370-4956-970a-f08b24cdcb4c",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "ec8a6307-26eb-41b7-a8ea-e349313cdabb",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "edcb6ffb-b009-4bc3-ba1c-e18729ed33e0",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "f10ad5ab-ef42-4e27-b25b-2fd9d5f09a12",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "f84e074b-ab63-414b-ba5a-ecd86f9c77c4",
            entityId: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
        ],
        id: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
        matchValue: "10624079708",
        positionX: -47,
        positionY: 1066,
      },
      {
        displayValue: "5511967389563",
        entityIdentification: "lit.phone",
        extraParams: [],
        id: "c9c9701b-c20c-412c-a383-4b369d2a6de9",
        matchValue: "5511967389563",
        positionX: -122,
        positionY: 1966,
      },
      {
        displayValue: "JOSE FREDERICO CIMINO MANSSUR",
        entityIdentification: "lit.lawyer",
        extraParams: [
          {
            id: "1e2f1f20-e5bc-409b-8ce8-31f29bbfba72",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "2c627e54-4656-4841-b2f3-6b1f0e77e648",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "2caa5db9-d059-43a6-b136-588024cc69aa",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "30750a68-dcef-4eca-8b63-a647a6aa0bbd",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "3c18944c-bf55-4d57-ac31-f76a6689ee28",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "oabNumber",
            displayValue: "Número OAB",
            value: "194746",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "403db219-5061-4c73-98bd-228acc5c828d",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "65e99518-83c9-4d5e-b24b-ddef89bd49d0",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "6aa6ba4a-636f-4fcc-bb95-31e6e8bd8744",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "779fb05a-5808-43e1-a189-fcea26aa1fa8",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "7ee204ed-85ab-474c-bded-3dfb3792f5c4",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "86131040-5cb0-4f63-851c-bef44532140c",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "8e3cfe06-8158-45a8-b94a-f72ffc2ca948",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "oabState",
            displayValue: "Estado OAB",
            value: "SP",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "9b6c5d99-e552-435f-b7ef-01561a3f75e0",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "9b9ae823-1c67-4a3d-8fbc-19431a1af16f",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "ba55f1e4-d7fc-4128-b327-7092ef69dedb",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "bca93a5e-4784-4d12-aca8-116205d78e18",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "ed9d65fb-96b0-4c36-930d-b44784926e6e",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "f0d79029-56c2-499b-9025-53edd30ceaa1",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "fac257ef-d137-40bc-b3a5-4843ac679d30",
            entityId: "dcac0d32-0934-438c-beff-957c383335c0",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
        ],
        id: "dcac0d32-0934-438c-beff-957c383335c0",
        matchValue: "28389656892",
        positionX: 253,
        positionY: 946,
      },
      {
        displayValue: "MARILIA MICKEL MIYAMOTO NALETTO TEIXEIRA",
        entityIdentification: "lit.lawyer",
        extraParams: [
          {
            id: "09b4e1c0-d3aa-4dbb-810e-a9c75c26da61",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "33d0561c-122d-4a3c-b37e-983570421376",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "360cc466-38e7-4251-a851-1e12469a67a3",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "4b05184b-584b-4a39-8b3a-2c115c6e5d4d",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "4dd330c1-48d4-4ec9-b2e3-b403cba50aee",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "4f8bea3b-75f0-478e-9002-037c86206ba1",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "507067a0-6358-4545-b3b7-3f49a6b5212a",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "51e72257-a464-4a71-953f-512f150e56e3",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "81d3819f-8c8c-4d41-9451-af622419215f",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "863670ba-261f-4e62-9aed-316711f440e6",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "9e78e5d4-8dc4-4ca2-88a0-137c397d16d0",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "9f3e6d0d-33ed-40c8-a48c-0f6d9aac7d5c",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "oabState",
            displayValue: "Estado OAB",
            value: "SP",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "a4e79b62-8dd2-45b4-bb10-9276dfc36a69",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "af01ed4c-8ff0-40e2-8fe9-8d164da53c93",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "bfece7ba-c949-457f-b64f-9af6e20603e2",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "c10c5f9b-b04b-4a39-9dea-fae914ffb6c3",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "c4e664a4-7016-4e65-89c5-4d50b4dca1ef",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "oabNumber",
            displayValue: "Número OAB",
            value: "271431",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "dac7085e-7b58-4a26-9721-0e2aa95eb055",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
          {
            id: "ec979c2e-d186-4b15-9a2d-1b688a71233c",
            entityId: "e5da52b2-0906-47dd-bf5c-622fa5240798",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:12.000Z",
            updatedAt: "2023-04-20T21:10:12.000Z",
          },
        ],
        id: "e5da52b2-0906-47dd-bf5c-622fa5240798",
        matchValue: "22291945858",
        positionX: 328,
        positionY: 1966,
      },
      {
        displayValue: "EULER DE MIRANDA FAJARDO",
        entityIdentification: "lit.lawyer",
        extraParams: [
          {
            id: "0526986a-5581-43f1-935a-615903b0dffb",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "oabState",
            displayValue: "Estado OAB",
            value: "DF",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "0ab33ec1-d1d1-4214-8b4c-fc91933f2825",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "3d7da186-4353-42fd-8389-05c919d4a342",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "42b98ac0-fa7b-4a36-9dd1-186883e4375f",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "6a19c974-c8c0-4153-a416-66925fb1a40f",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "785c3162-ea97-4102-9e6d-dd665a8023ce",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "7f873d8f-36ed-4d64-b1b5-dfa13feb4d67",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "8e1acc29-3c41-4874-81b5-cb4876f7fdf5",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "b606064a-0a76-4dfd-8304-4bd6662fc7d1",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "b960a387-671d-479f-b4fd-34e5ff791b58",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "b9d7e9a7-d242-470a-8d57-de711bf13690",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "bace6a23-c64e-4a57-bcff-b33d6132de69",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "baf38df2-fd50-4731-a09a-d62cec5ec42b",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "c6b3a735-6a67-4627-af8d-043a9635a9d7",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "cd8b221e-95c4-456d-9082-ee3eb1173975",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "oabNumber",
            displayValue: "Número OAB",
            value: "010092",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "d8a4c6b7-0c71-4587-998f-50e64e5ffa2e",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "e631fa3a-2612-41d6-97d9-f20d3f93b313",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "e78ed09e-2689-40e3-b2c3-504eb2baa71b",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "f99d635c-2f85-49d8-b023-0c458ed98288",
            entityId: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
        ],
        id: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
        matchValue: "38533740182",
        positionX: 253,
        positionY: 826,
      },
      {
        displayValue: "00079862020198260008",
        entityIdentification: "lit.lawsuit",
        extraParams: [],
        id: "f0360c4d-a0f3-440f-82ed-80d1f6db6323",
        matchValue: "00079862020198260008",
        positionX: 253,
        positionY: 1456,
      },
      {
        displayValue: "OLNEI ABDAO",
        entityIdentification: "lit.lawyer",
        extraParams: [
          {
            id: "0cb695a5-5a20-4a40-b213-dbc56e806b99",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "1bcfa3ff-32e2-4ffc-91cb-6b4036daf7e8",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "25333e98-719d-4f56-a7a2-cd76dfa83578",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "67e87200-f841-47cc-96e2-6bd4a041c380",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "oabNumber",
            displayValue: "Número OAB",
            value: "046532",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "6db8d68c-2d25-46e0-b8fa-56ea68b4894f",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "8a14dd0d-a3a7-4ecb-8955-0769fb0edd18",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "a6f620c2-e890-4660-816f-f64b70f976a8",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "af1135da-45fb-4c46-af2f-1cc18c26ce05",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "b08c3565-95c6-4bb8-84f3-e7a6db0341df",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "b1da58f5-8e21-4556-b7e2-1544510d7c0e",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "bc13096e-a74e-4a12-a710-da6697ab79d6",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "bc2d8185-012e-4bfa-9911-7336f59df3a0",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "cba0e2e2-25a2-405e-8fc7-cf51fefbad69",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "e14c2cfc-e4ab-4907-9ef5-3839e32dec99",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "ebe3f55b-61da-48c1-bb44-29100f08baeb",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "oabState",
            displayValue: "Estado OAB",
            value: "DF",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "ed15c8c9-bbb8-4ab4-9bd0-df66bc135446",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "f006724d-d6e3-4c95-bf2b-fe58d5e99de2",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "f48503ed-fcdc-45e8-ba05-86750e219a8d",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
          {
            id: "fb95688d-fc75-486d-9e94-dd292303b25b",
            entityId: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:11.000Z",
            updatedAt: "2023-04-20T21:10:11.000Z",
          },
        ],
        id: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
        matchValue: "38983524120",
        positionX: 553,
        positionY: 826,
      },
      {
        displayValue: "00164175320138260008",
        entityIdentification: "lit.lawsuit",
        extraParams: [],
        id: "f3de34ad-4ade-44fd-aebd-4d2eac4c5956",
        matchValue: "00164175320138260008",
        positionX: -47,
        positionY: 1696,
      },
      {
        displayValue: "JOSE FREDERICO CIMINO MANSSUR",
        entityIdentification: "lit.lawyer",
        extraParams: [
          {
            id: "150afa04-2cf6-4378-ad18-a2cf9a3c22b0",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "finance",
            displayValue: "Rendimentos",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "1ea97fbd-c47b-472a-9305-b023c6145b7b",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "cnhValidity",
            displayValue: "Validade CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "24815a2f-24f8-4896-9b20-8abd74d129b2",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "cnhCategory",
            displayValue: "Categoria CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "30f06150-6b0e-4e64-8aae-a7ceab866fd3",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "oabState",
            displayValue: "Estado OAB",
            value: "SP",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "37e9983f-70bf-4cd2-b149-a5668fdb65d1",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "fatherName",
            displayValue: "Nome do Pai",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "530bdae9-be08-4b8d-996f-443c9ac2eda3",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "motherName",
            displayValue: "Nome da Mãe",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "535430d9-793d-4d14-aa11-2db82deec676",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "cnh",
            displayValue: "CNH",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "5e43ae39-cec7-4044-8121-cef4f593f040",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "oabNumber",
            displayValue: "Número OAB",
            value: "194746",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "8bfaa60b-6bda-4fb6-8ee1-4efbcbf850fb",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "birthCity",
            displayValue: "Cidade Natal",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "9143841c-4498-4e8a-bead-9694b321c5b6",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "voterRegistrations",
            displayValue: "Título de Eleitor",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "9aaa71a3-3782-4529-8ce5-343c2bda08e6",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "rg",
            displayValue: "RG",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "a2b583a8-ff45-44a7-bc77-823e81ccd30b",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "schooling",
            displayValue: "Escolaridade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "a4038767-b780-4305-bee4-ab10c5fad4df",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "nis",
            displayValue: "NIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "bebb21d5-5884-4c2d-a688-0f6ba67e02f7",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "age",
            displayValue: "Idade",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "c3caa1ac-392a-4a9c-b978-a987ed55e332",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "gender",
            displayValue: "Gênero",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "c5682b5b-bf0d-49dc-befb-9d00f55c8657",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "socialClass",
            displayValue: "Classe Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "cb5c1609-4b62-490d-8046-fc9087771d7b",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "pis",
            displayValue: "PIS",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "ddc2f355-4172-4136-8a5b-fe4ccbe37d44",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "birth",
            displayValue: "Nascimento",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
          {
            id: "edfc41c5-cb1d-4c09-9277-7b90a6629f11",
            entityId: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
            paramId: "socialName",
            displayValue: "Nome Social",
            value: "",
            type: "string",
            deletedAt: null,
            deletedBy: null,
            createdAt: "2023-04-20T21:10:10.000Z",
            updatedAt: "2023-04-20T21:10:10.000Z",
          },
        ],
        id: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
        matchValue: "28389656892",
        positionX: -347,
        positionY: 826,
      },
    ],
    links: [
      {
        id: "016ac41f-e438-4a5d-9dd0-b651917233ab",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "07a34952-f818-483f-8cee-cad5a014dc1f",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "069611d7-887c-4add-ace2-9a0018d35786",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "0928ec28-ae81-4ef7-ab39-5210c78a5e2e",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "1cd37642-f0fa-49b7-9cff-e00227e9821c",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "0af93c6e-61f1-4fdd-abb0-3e1ebad173b9",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "722bfa1b-d0d5-4803-9f26-dbe0b52372d7",
        target: "bf55a677-9b4c-41c8-ab10-be92585924f6",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "0c111b3c-e41c-4da9-aa55-ed2e3fa6ffe4",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "7361d2c0-6c8f-4012-bbcb-c5e594a7b0ff",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "191d0ebd-534e-4948-b19c-234fe0dc82d9",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "b67ffe47-0def-4318-ad54-5cc86e9b376f",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "1fcdf976-91b2-4fea-84ee-4cfd3b4be86b",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "2160167e-a083-497b-b05d-b04e32f6e58f",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "ec98f699-da35-4bf6-b3bb-99984486c4a2",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "23f38150-6794-41b0-8701-b49c6effd7d9",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        target: "ae387334-1270-4d82-9d7b-4c9ca4f704dc",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "25f1e429-b9f1-4877-b822-e387ffe2e4d3",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        target: "91081f3b-f1c1-47f0-a87f-c702b095b04a",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "28ae7ec3-e337-4759-92e8-7b200591e8a3",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "fa620bbc-8c12-4bc1-8ed1-1ebf783bddda",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "291a5493-fdd5-4ed0-9687-c78c45c5160b",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "3d6d6422-0b6b-442f-813f-1089123983f4",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "c2657f40-7530-439c-b8dd-17a3913a92e5",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "4141f2f7-0d7c-40ba-a689-040f8066d537",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        target: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "44c7a992-8040-482b-ae2a-69e576d87d05",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "45cc2ac6-08cf-4793-b079-ce5bd2529b66",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        target: "f3de34ad-4ade-44fd-aebd-4d2eac4c5956",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "4c10af73-a7c2-451c-9932-a9040279d8fb",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "04d1f6bc-0c49-4968-8b33-795ac10387f5",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "4fdb86d1-04cf-447d-b49f-eb2e1d39c5f6",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        target: "78a7528e-6473-4c44-81af-803e4b57e8e4",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "53cb3985-6c6a-4c86-91c4-7eca86d0cf65",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        target: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
        label: "",
        color: "#f00",
        style: "dashed",
        router: "orthogonal",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "560a835b-3210-4ba6-b02b-d4ebae48b0df",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        target: "ab801173-63c7-4b20-90a1-bb6955b4bdf9",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "578a0b86-4282-497f-a570-4b741148b1f6",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
        target: "2223ec1a-8069-450e-b8e1-3a36efb9636b",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "5a972199-547a-4f5f-9fbe-9cae1a24ad4b",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "722bfa1b-d0d5-4803-9f26-dbe0b52372d7",
        target: "66cebb63-427c-4f94-b6b4-e8362f1bc6f4",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "5c1d81ac-9a14-4cf2-bbf2-f7009f230ecc",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "6fc0e746-e9f7-4034-9532-d693ab81895c",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "627aded0-e993-416e-84a1-e1c32a0f4df6",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "722bfa1b-d0d5-4803-9f26-dbe0b52372d7",
        target: "e5da52b2-0906-47dd-bf5c-622fa5240798",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "630a4abc-ca9f-413a-95a1-88b8113c84f5",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "766d9184-ad3b-4c93-8e9b-12f3cd7b3ed2",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "6388b16e-a6c4-4ef5-b511-3cad847bdf18",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "4b1b997c-2b61-42ac-88d5-b991537ba0a4",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "682ffd6b-8309-473c-8034-2bee5ea79d1d",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "61bb4d0c-560f-409f-9557-119880c17c65",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "6b953115-7324-4a43-a6d3-7617163e2314",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "5527e7b1-75cc-4ddf-a4e2-4de7b60d3fba",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "783bdec4-afbd-40b3-8155-36f8d9d40b70",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "f25aa3d3-6b1f-43ad-b072-380da9a15224",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "7b1c2939-0e55-49fe-8ecb-0b25eb3a91ff",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "bc0358ed-0aef-4b71-b4c2-ba7a630724c4",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "81ea5e75-c83c-4b18-b24d-0e5416ef2132",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
        target: "c9c9701b-c20c-412c-a383-4b369d2a6de9",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "8293d4aa-c525-424c-b335-a9270fdc200f",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "722bfa1b-d0d5-4803-9f26-dbe0b52372d7",
        target: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "87b81122-caf3-4304-a28f-d02e00d3c7f2",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "722bfa1b-d0d5-4803-9f26-dbe0b52372d7",
        target: "33c9ef16-9646-4fae-a1bb-986ce25abc12",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "8f4f151a-23bb-44fa-9042-633743a94ef1",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "8fe69efb-0bae-4215-b5eb-70eaa9a6374b",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "989ef030-d4af-4046-a900-5c785973fb5f",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        target: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "9e713b10-6f72-4598-b086-68e4dd914763",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "722bfa1b-d0d5-4803-9f26-dbe0b52372d7",
        target: "558c1f73-25d2-4d1d-ab70-919bd0ae0c4f",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "a734919a-6d08-40e6-bff0-4eeb7b5c6003",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        target: "a2538866-d654-4563-ae4f-6524cb640510",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "abfef5b2-f467-460b-8ecf-c715a612a7d1",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "4460a3b6-c0c7-4a53-a604-86b5f08720e5",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "b3b4e47e-f26e-4d50-920f-f851ed6ec23a",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "0b7946e9-283e-44db-86cc-5df8c00bce1e",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "b621f1d7-eb57-4066-a236-529078c9ec4a",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "b5472465-3fe0-4d96-96d0-67ee180e71c1",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "cb2f4f1d-0940-461e-85fe-12f239b026c6",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "5025cb3b-2a3a-4a27-8679-0f07718996cc",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "cb9ab394-d4c4-46c1-adca-125fe416383a",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "dcac0d32-0934-438c-beff-957c383335c0",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "cd631478-9a52-41dd-88be-a81160f5c50f",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        target: "722bfa1b-d0d5-4803-9f26-dbe0b52372d7",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "cd912720-bea5-4320-a08c-8fac5ffd4dcd",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "33c9ef16-9646-4fae-a1bb-986ce25abc12",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "cf0eb61a-c96b-4d03-acd3-0174adc69fe0",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "c459f6e6-3694-4e27-9d63-2771e82e0d00",
        label: "",
        color: "#4d86b6",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "cf7d5bee-ac33-45aa-b909-7c68b4c942bd",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "bf55a677-9b4c-41c8-ab10-be92585924f6",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "d060fd07-3db4-4e22-ab05-54d9267708fa",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        target: "34e39c5c-46cd-448f-841a-9d61020c68fb",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "e4e4730a-59f6-44b2-a8cf-0422689c47af",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "722bfa1b-d0d5-4803-9f26-dbe0b52372d7",
        target: "38295b97-3936-4dbd-b45e-a24af0f8dffc",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "eaec28e4-842b-444a-8b70-4c61ba46da71",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        target: "f0360c4d-a0f3-440f-82ed-80d1f6db6323",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "eafa5fda-46d7-4513-bb3a-5baa91dd65e9",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "109ca169-4643-4854-8a6c-7331dfee98b3",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        "id": "eb79bf48-59ee-407c-a995-c03980fd504f",
        "investigationId": "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        "source": "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        "target": "387c9439-29ba-4386-a01a-f546dc596718",
        "label": "",
        "color": "#ccc",
        "style": "dashed",
        "router": "metro",
        "deletedAt": null,
        "deletedBy": null,
        "createdAt": "2023-04-20T21:10:12.000Z",
        "updatedAt": "2023-04-20T21:10:12.000Z",
      },
      {
        id: "eedbc5f3-3b62-40fc-82ff-8ed480416ee7",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "782eaff1-8e5c-4950-aa22-6f9777003a2e",
        target: "023fc111-4559-4cec-80ed-b0fc3bf23c12",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
      {
        id: "f1423895-56fc-490c-906b-99943d55acf7",
        investigationId: "c107e804-ab4c-4ea9-a57c-cb7214122cf5",
        source: "49503303-f8f8-48f8-9c7d-2b240b6bdf93",
        target: "38882acc-0092-422f-b3b2-9a403d83e3a2",
        label: "",
        color: "#ccc",
        style: "dashed",
        router: "metro",
        deletedAt: null,
        deletedBy: null,
        createdAt: "2023-04-20T21:10:12.000Z",
        updatedAt: "2023-04-20T21:10:12.000Z",
      },
    ],
  },
};
