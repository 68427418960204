import { ReactComponent as Company } from '../../images/company.svg';
import { ReactComponent as Person } from '../../images/person.svg';
import { ReactComponent as Bank } from '../../images/bank.svg';
import { ReactComponent as Process } from '../../images/process.svg';
import { ReactComponent as Address } from '../../images/address.svg';
import { ReactComponent as Phone } from '../../images/phone.svg';
import { ReactComponent as Email } from '../../images/email.svg';
import { EntityIdentification } from '../../pages/data';

export function getIcon(entityType: string) {
    switch (entityType) {
        case EntityIdentification.LitCompany:
            return <Company />;
        case EntityIdentification.LitPerson:
            return <Person />;
        case EntityIdentification.LitLawyer:
            return <Bank />;
        case EntityIdentification.LitLawsuit:
            return <Process />;
        case EntityIdentification.LitUnknown:
            return <Address />;
        case EntityIdentification.LitPhone:
            return <Phone />;
        case EntityIdentification.LitUnknown:
            return <Email />;
        default:
            return <Company />;
    }
}

export function getIconColor(node:any) {
    switch (node.data.entityType) {
        case EntityIdentification.LitCompany:
            return "#E4734F";
        case EntityIdentification.LitPerson:
            return "#2A6EC7";
        case EntityIdentification.LitLawyer:
            return "#3EA364";
        case EntityIdentification.LitLawsuit:
            return "#7656BB";
        case EntityIdentification.LitUnknown:
            return "#D765C4";
        case EntityIdentification.LitPhone:
            return "#2192D1";
        case EntityIdentification.LitUnknown:
            return "#20BDB4";
        default:
            return <Company />;
    }
}

