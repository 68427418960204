import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Paper, SvgIconTypeMap } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import { OverridableComponent } from '@mui/material/OverridableComponent';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

const GenericCard:React.FC<{title:string; value:number, icon:React.ReactNode}> = ({title,value,icon}) => {
  return (
      <Paper sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "24px",
      gap: "16px",
      width: "257px",
      height: "132px",
      backgroundColor: "primary.dark",
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
      borderRadius: "8px"
        }}>
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "0px",
          gap: "8px",
          width: "193px",
          height: "44px",
        }}>
          <Box sx={{ 
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: "8px",
          gap: "10px",
          width: "44px",
          height: "44px",
          borderRadius: "8px",
            backgroundColor:"primary.main", color:"primary.contrastText"}} >
          {icon}
          {/* <AccountTree sx={{ 
            width: "28px",
            height: "28px",
            color:"primary.contrastText"}}/> */}
            </Box>
          <Typography  sx={{color:"primary.contrastText", fontWeight:"semi-bold"}} component="p" variant="body1">
          {title}
          </Typography>
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px",
          gap: "24px",
          width: "209px",
          height: "24px",
        }}>
          <Typography  sx={{color:"primary.contrastText", fontWeight:"bold"}} component="p" variant="body2">
            {value}
          </Typography>
          <Visibility sx={{color:"primary.contrastText"}}/>
          </Box>
      </Paper>
  );
}

export default GenericCard;