import { Box, Grid, TextField } from '@mui/material';
import React from 'react';
import { Handle, Position } from 'reactflow';
import { getIcon } from './getIcon';
import GenericNode from './GenericNode';
import { GroupNodeInterface } from '../../pages/data';


const GroupNode: React.FC<GroupNodeInterface> = (props) => {
    return (
    <>
    <Handle type="target" position={Position.Top} />
    <Grid
      container
      direction="column"
      display={"flex"}
      justifyContent="center"
      alignItems="flex-start"
      spacing={1}
      gap={2}
      padding={2}
      border={1.5}
      width={"300px"}
      borderColor={"#DDDDD"}
      borderRadius={4}
      sx={{backgroundColor:"neutral.main"}}
      >
        <Grid 
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        padding={0}
        gap={2}
        width={"100%"}
        >
          <Grid item>
            <Box sx={{width: "40px", height: "40px", backgroundColor: "transparent"}}>
              {getIcon(props.data[0].data.entityType)}
              </Box>
          </Grid>
          <Grid item>
            <TextField sx={{borderRadius:200}} id="outlined-basic" label="Search" variant="outlined" />
          </Grid>
        </Grid>
      
      {props.data.map((node:any, index:number) => (
        <Grid sx={{width:"100%"}} item key={index}>
          <Grid item draggable>
          <GenericNode displayIcon={false} {...node}/>
            </Grid>
        </Grid>
      ))}
    </Grid>
      </>
    )
  }

export default GroupNode;