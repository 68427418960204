import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet
} from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import InvestigationBoard2 from './pages/InvestigationBoard2';


const App: React.FC = () => {
  const { isAuthenticated } = useAuth();

  const ProtectedRoutes = (props:any) => {
    if (isAuthenticated) {
      return <Outlet/>;
    } else {
      return <Navigate to="/login" />;
    }
  };

  return (
    <ThemeProvider theme={theme}> 
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage/>} />
        <Route path="/" element={<ProtectedRoutes/>}>
        <Route path="/protected" element={<Dashboard/>} />
        <Route path="/board" element={<InvestigationBoard2/>} />
        </Route>
      </Routes>
    </Router>
    </ThemeProvider>
  );
};

export default App;