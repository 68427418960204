import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
  Edge,
  Node,
  MiniMap
} from "reactflow";
import GenericNode from "../components/Nodes/GenericNode";
import { Grid } from "@mui/material";
import GroupNode from "../components/Nodes/GroupNode";
import 'reactflow/dist/style.css';
import { createRandomNode } from "../utils/randomNodes";
import DefaultLayout from "../components/DefaultLayout";
import { investigationBoardList } from "../components/listItems";
import { getIconColor } from "../components/Nodes/getIcon";
import { parseInvestigationResponseDataEntitiesToNodeInterface, parseInvestigationResponseDataLinksToEdge} from "./data";
import cytoscape from "cytoscape";
import { v4 } from "uuid";

const nodeTypes = {
  genericNode: GenericNode,
  groupNode: GroupNode,
};

const initialEdges = parseInvestigationResponseDataLinksToEdge();
const initialNodes = parseInvestigationResponseDataEntitiesToNodeInterface();

function organizeNodes(edges:Edge<any>[],nodes: Node<any, string | undefined>[],setNodes:any,setEdges:any){

  let options = {
    name: 'concentric',
    fit: true, // whether to fit the viewport to the graph
    padding: 30, // the padding on fit
    startAngle: 3 / 2 * Math.PI, // where nodes start in radians
    sweep: undefined, // how many radians should be between the first and last node (defaults to full circle)
    clockwise: true, // whether the layout should go clockwise (true) or counterclockwise/anticlockwise (false)
    equidistant: false, // whether levels have an equal radial distance betwen them, may cause bounding box overflow
    minNodeSpacing: 10, // min spacing between outside of nodes (used for radius adjustment)
    boundingBox: undefined, // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
    avoidOverlap: true, // prevents node overlap, may overflow boundingBox if not enough space
    nodeDimensionsIncludeLabels: false, // Excludes the label when calculating node bounding boxes for the layout algorithm
    height: undefined, // height of layout area (overrides container height)
    width: undefined, // width of layout area (overrides container width)
    spacingFactor: 10, // Applies a multiplicative factor (>0) to expand or compress the overall area that the nodes take up
    concentric: function( node:any ){ // returns numeric value for each node, placing higher nodes in levels towards the centre
    return node.degree();
    },
    levelWidth: function( nodes:any ){ // the variation of concentric values in each level
    return nodes.maxDegree()/4;
    },
    animate: false, // whether to transition the node positions
    animationDuration: 500, // duration of animation in ms if enabled
    animationEasing: undefined, // easing of animation if enabled
    animateFilter: function ( node:any, i:any ){ return true; }, // a function that determines whether the node should be animated.  All nodes animated by default on animate enabled.  Non-animated nodes are positioned immediately when the layout starts
    ready: undefined, // callback on layoutready
    stop: undefined, // callback on layoutstop
    transform: function (node:any, position:any ){ return position; } // transform a given node position. Useful for changing flow direction in discrete layouts
  };
  
  const cy = cytoscape({
    headless: true,
    elements: {
      nodes: nodes.map((n) => ({ data: { id: n.id, label: n.id } })),
      edges: edges.map((e) => ({ data: { source: e.source, target: e.target } })),
    },
    style: [
      {
        selector: 'node',
        style: {
          shape: 'rectangle', // Set node shape
          width: '302px', // Set node width
          height: '150px', // Set node height
        },
      },
    ],
  });
  

const layout = cy.layout(options
  // {name:"concentric",fit:true,spacingFactor:10,avoidOverlap:true,concentric:function( node:any ){ return node.degree(); },levelWidth:function( nodes:any ){ return 1; },minNodeSpacing:12}
  );
layout.run();

// Get the updated node positions
const updatedNodes = cy.nodes().map((node) => ({
  id: node.id(),
  label: node.data('label'),
  x: node.position('x'),
  y: node.position('y'),
}));

const lEls = nodes.slice().map((n) => {
  const nodeWithPosition = cy.getElementById(n.id)[0]
  return {...n,position : {
    x: nodeWithPosition.position('x'),
    y: nodeWithPosition.position('y')
  }};
});

  setNodes(lEls);
};

function InvestigationBoard2() {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
  const reactFlowWrapper = useRef<any>(null);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  
  function onNodesObserver(event:any){
    // const nodes = reactFlowInstance.getNodes()
    console.log("Nodes changed",nodes)
  }

  useEffect(() => {
    if(nodes.length)return;
    setNodes(initialNodes)
  }, [reactFlowInstance]);

  useEffect(() => {
    if(!nodes.length)return;
    organizeNodes(edges,nodes,setNodes,setEdges);
  }, [reactFlowInstance,nodes.length]);

  const onConnect = useCallback((params:any) => {
    //chamar o back e criar o link novo
    console.log("Nova conexao",params)
    setEdges((els) => addEdge(params, els))
  }, []);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      if(reactFlowWrapper.current === null) return;
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const data = event.dataTransfer.getData('text/plain')
      if (typeof data === 'undefined' || !data) {
          return;
      }
      const type = JSON.parse(data);

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      setNodes((nds) => nds.concat(createRandomNode(v4(),position,type.entityType,type.nodeType)));
    },
    [reactFlowInstance]
  );

  return (
    <div className="dndflow">
       <ReactFlowProvider>
      <div className="reactflow-wrapper" ref={reactFlowWrapper}>
    <DefaultLayout items={investigationBoardList}>
    <Grid container component="main" sx={{ height: '100vh' }}>
  <ReactFlow
    edges={edges}
    nodes={nodes}
    nodeTypes={nodeTypes as any}
    onNodesChange={(event)=>{
      onNodesObserver(event)
      onNodesChange(event)
    }}
    onEdgesChange={onEdgesChange}
    onInit={setReactFlowInstance}
    onDrop={onDrop}
    onDragOver={onDragOver}
    onConnect={onConnect}
    minZoom={0.05} 
    maxZoom={1.5}
    fitView
    onlyRenderVisibleElements
    >
    <Background color="#aaa" gap={16} />
    <Controls/>
    <MiniMap nodeColor={getIconColor as any}  nodeStrokeWidth={3} zoomable pannable />
  </ReactFlow>
  </Grid>
      </DefaultLayout>
      </div>
      </ReactFlowProvider>
    </div>
  )
}

export default InvestigationBoard2;