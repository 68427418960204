import React from "react";
import { Badge, Box, CssBaseline, Divider, Grid, IconButton, List, styled, Toolbar, Typography } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar"
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MuiDrawer from "@mui/material/Drawer";
import InvestigacoesLogo from "../components/InvestigacoesLogo";
import { useNavigate } from "react-router-dom";


interface Props {
    children?: React.ReactNode;
    items: ()=>React.ReactNode;
  }

const drawerWidth = 270;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{open:boolean}>(({ theme, open }) => ({
  backgroundColor: '#ffffff',
  zIndex: theme.zIndex.drawer,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      backgroundColor: theme.palette.primary.dark,
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const DefaultLayout: React.FC<Props> = (props) => {
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
      setOpen(!open);
    };

    return (
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
    <AppBar position="absolute" open={open}>
    <Toolbar
      sx={{
        pr: '24px', // keep right padding when drawer closed
      }}
    >
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer}
        sx={{
          marginRight: '36px',
          ...(open && { display: 'none' }),
        }}
      >
        <MenuIcon />
      </IconButton>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        sx={{ flexGrow: 1 }}
      >
        Dashboard
      </Typography>
      <IconButton color="inherit">
        <Badge badgeContent={4} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
    </Toolbar>
  </AppBar>
  <Drawer variant="permanent" open={open}>
    <Box mt={5} mx={1}>
      <InvestigacoesLogo displayText={open}/>
    </Box>
    <Toolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: [1],
      }}
    >
    </Toolbar>
    <Divider />
    <List sx={{color:"primary.contrastText"}} component="nav">
      {props.items()}
    </List>
  </Drawer>
  <Grid sx={{position:"relative", opacity:100}}>
          <IconButton sx={[   (theme) => ({
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }),{
            backgroundColor:"primary.dark", 
            position:"absolute", 
            top:12,
             right:-16, 
             zIndex:1301, 
             color:"primary.contrastText"}]}  onClick={toggleDrawer}>
            {open?<ChevronLeftIcon />:<ChevronRightIcon/>}
          </IconButton>
    </Grid>
    <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
            {props.children}
        </Box>
        </Box>
    )
}

export default DefaultLayout;